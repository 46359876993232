import React from "react";
import { WagmiConfig } from "wagmi";
import {  wagmiEntity } from "./web3Config";

const Web3Wrapper = ({ children }) => {
  return (
    <WagmiConfig config={wagmiEntity}>
        {children}
    </WagmiConfig>
  );
};

export default Web3Wrapper;
