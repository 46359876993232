import { AuthProvider } from "@arcana/auth";
import { publicProvider } from "wagmi/providers/public";
import { ArcanaConnector } from "@arcana/auth-wagmi"
import { configureChains, createConfig } from "wagmi";
import { mainnet, polygon,sepolia } from 'wagmi/chains'
import { amoyNetwork } from "../utils/amoyConfig";
export const arcanaAuth = new AuthProvider(process.env.REACT_APP_ARCANA_CLIENT_ID,
    {
        theme: 'dark',           // default: dark
        alwaysVisible: false,     // default: true, wallet always visible
        setWindowProvider: true,  // default: false, window.ethereum not set
    }
);

export const connector = (selectedChains) => {
    return new ArcanaConnector({
        chains:selectedChains,
        options: {
            auth: arcanaAuth,
        },
    });
};

const { chains, publicClient } = configureChains(
    [polygon, mainnet,sepolia,amoyNetwork],
    [publicProvider()]
);

export const wagmiEntity = createConfig({
    autoConnect: true,
    connectors: [connector(chains)],
    publicClient,
});
