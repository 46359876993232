import { apiClient, web3Client } from '../../api/clients';
const environment = process.env.REACT_APP_WALLET_TYPE;


const getNotifications=(url,customerId,type)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(`${url}/${customerId}/${type}`)
    }else{
        return apiClient.get(`${url}/${customerId}/${type}`)
    }
}
const readNotification = (customerId,appName,obj) => {
    if(environment === 'non_custodial'){
        return web3Client.put(`Notification/UpdateReadCount/${customerId}/${appName}`,obj)
    }else{
        return apiClient.put(`Notification/UpdateReadCount/${customerId}/${appName}`,obj)
    }
}
export { getNotifications, readNotification}