import React, { useEffect, useState } from 'react'
import { Layout as AntLayout } from 'antd';
import AppContent from './appContent';
import AppFooter from './footer';
import AppHeader from './header';
import OnBoarding from './onboard.component';
import { useAuth0 } from '@auth0/auth0-react';
import { connect, useDispatch } from 'react-redux';
import { withRouter,useHistory,useLocation } from 'react-router-dom';
import { profileSuccess, setToken, userLogout } from "../reducers/authReducer";
import { useAccount, useConnect } from 'wagmi';
import { arcanaAuth } from '../auth/web3Config';
import { clearUserInfo, getArcanaSupportedChains, handleWeb3Login, setGettingUserInfo, setWeb3User } from '../reducers/configReduser';

const { Content } = AntLayout;
const Layout = (props) => {
    const {isLoading:web3Loader}=useConnect()
    const {isConnecting,isReconnecting}=useAccount();
    const history=useHistory()
    const {pathname}=useLocation()
    const dispatch=useDispatch()
    const [isWeb3] = useState(process.env.REACT_APP_WALLET_TYPE === "non_custodial");
    const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently, error, isLoading } = useAuth0();
    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            props.acctoken(token)
        } catch (errors) {
        }
    };

    useEffect(() => {
        if (!isWeb3) {
            if (!isAuthenticated) {
                loginWithRedirect();
            } else {
                props.updateProfile(user)
                callApi()
            }
        }
    }, [isWeb3])

    useEffect(()=>{
        if(isWeb3 && (!props.userProfile || !props.userProfile?.id) && !isReconnecting && !isConnecting && !props.gettingUserInfo && !web3Loader){
            history.push('/welcome');
          }
          if(isWeb3 && pathname.includes('/welcome') && props.userProfile?.id){
            history.replace('/dashboard');
          }
    },[isWeb3,props?.userProfile?.id,web3Loader,pathname,isReconnecting,isConnecting,props.gettingUserInfo])


    useEffect(() => {
        if(isWeb3){
            arcanaAuth?.provider?.on("connect", handleArcanaConnect);
            arcanaAuth?.provider?.on("disconnect", handleArcanaDisconnect);
        }
        return () => {
            if(isWeb3){
                arcanaAuth?.provider?.removeListener("connect", handleArcanaConnect);
                arcanaAuth?.provider?.removeListener("disconnect", handleArcanaDisconnect);
            }
        }
      }, [isWeb3])
      useEffect(() => {
        if(props.userProfile?.id && isWeb3){
          dispatch(getArcanaSupportedChains())
        }
      }, [props.userProfile?.id,isWeb3])
      const handleArcanaConnect = async () => {
        dispatch(setGettingUserInfo('connecting'))
        const userInfo = await arcanaAuth.getUser();
        const isLoggedIn = await arcanaAuth.isLoggedIn();
        const info = { ...isLoggedIn, ...userInfo }
        dispatch(setToken(info?.loginToken));
        dispatch(handleWeb3Login(info, () =>{
          dispatch(setWeb3User(info));
          dispatch(setGettingUserInfo(''))
        }, () => {
          dispatch(setGettingUserInfo(''))
        }));
      };
      const handleArcanaDisconnect = async () => {
        const isLoggedIn = await arcanaAuth.isLoggedIn();
        dispatch(setWeb3User({ isLoggedIn }));
        dispatch(setToken(null));
        dispatch(clearUserInfo());
        dispatch(userLogout());
        dispatch(setGettingUserInfo(''))
      };
    if(!isWeb3){
        if (!isAuthenticated || !props.oidc.profile || !props.oidc.deviceToken) {
            return <h1 className="loader text-white app-loader" style={{ textAlign: 'center' }}>Loading .....</h1>;
        } else if (props.oidc.profile && !props.userProfile) {
            return <OnBoarding />;
        } else if (props.twoFA?.loading) {
            return <h1 className="loader text-white app-loader" style={{ textAlign: 'center' }}>Loading .....</h1>;
        } else if (error) {
            return <div>Oops... {error.message}</div>;
        } else if (isLoading) {
            return <h1 className="loader text-white app-loader" style={{ textAlign: 'center' }}>Loading .....</h1>;
        }
    }
    return (
        <AntLayout>
            <AppHeader />
            <div style={{ marginTop:props.location?.pathname === '/welcome'?"": "10px", zIndex: 100, Color: "red" }}>
                <Content
                    className=""
                >
                    <div className={` ${props.location?.pathname === '/dashboard' || props.location?.pathname === '/' ? "db-container" : null}`}>
                        <AppContent />
                    </div>
                    <AppFooter />
                </Content>
            </div>
        </AntLayout>)

}
const mapStateToProps = ({ oidc, userConfig }) => {
    return { oidc, userProfile: userConfig.userProfileInfo,gettingUserInfo:userConfig.gettingUserInfo }

}
const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (info) => { dispatch(profileSuccess(info)) },
        acctoken: (data) => { dispatch(setToken(data)) },
        dispatch
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
