import { deriveErrorMessage } from "../utils/deriveErrorMessage";

const formatError = (error, from) => {
  if (error && typeof error !== "string" && !from) {
    return deriveErrorMessage(error);
  }
  if (error && from === "contract") {
    return error?.details ||
      error?.cause?.reason ||
      error?.reason ||
      error?.message ||
      error?.error?.message ||
      error?.error?.error?.message ||
      error.fault ||
      error;
  }
  return error?.message || error;
};
export default formatError;
