import { apiClient, web3Client } from "../../api/clients";
import { ApiControllers } from "../../api/config";
const environment = process.env.REACT_APP_WALLET_TYPE;


const get = (url) =>{
    if(environment === 'non_custodial'){
        return web3Client.get(`${url}`);
    }else{
        return apiClient.get(`${url}`);
    }
}
const fetchNetworks = (coin,customerId) =>{
    if(environment === 'non_custodial'){
        return web3Client.get(`Merchant/NetWorkLookUp/${coin}/${customerId}`);
    }else{
        return apiClient.get(`Merchant/NetWorkLookUp/${coin}/${customerId}`);
    }
}
const save = (url,obj) =>{
    if(environment === 'non_custodial'){
        return web3Client.post(`${url}`,obj);
    }else{
        return apiClient.post(`${url}`,obj);
    }
}
const update = (url,obj) =>{
    if(environment === 'non_custodial'){
        return web3Client.put(`${url}`,obj);
    }else{
        return apiClient.put(`${url}`,obj);
    }
}
const fetchBatchPayoutDetails=(id,batchid)=>{
    if(environment === 'non_custodial'){
        return web3Client.get(ApiControllers.merchant+`batchpaymentview/${id}/${batchid}`)
    }else{
        return apiClient.get(ApiControllers.merchant+`batchpaymentview/${id}/${batchid}`)
    }
}
const getDetais = (url,customerId) =>{
    if(environment === 'non_custodial'){
        return web3Client.get(`${url}/${customerId}`);
    }else{
        return apiClient.get(`${url}/${customerId}`);
    }
}
export {get,fetchNetworks,save,update,fetchBatchPayoutDetails,getDetais}
