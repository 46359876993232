import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route as ReactRoute, Redirect } from 'react-router-dom';
import ProtectedRoute from '../auth/protectedRoute';

const CallbackPage = lazy(() => import('../auth/callback.component'));
const DashboardComponent = lazy(() => import('../components/dashboard.component'));
const Web3Dashboard = lazy(() => import('./web3Dashboard'));
const Vaults = lazy(() => import('../components/vaults'));
const PayIns = lazy(() => import('../components/payIns'));
const PayIn = lazy(() => import('../components/payIns/payIn'));
const Payouts = lazy(() => import('../components/payouts'));
const Payout = lazy(() => import('../components/payouts/payout'));
const BatchPayouts = lazy(() => import('../components/batchPayouts'));
const Payment = lazy(() => import('../components/batchPayouts/payment'));
const Transactions = lazy(() => import('../components/transactions'));
const TransactionDetails = lazy(() => import('../components/transactions/transactionDetails'));
const Payees = lazy(() => import('../components/payees'));
const Auth0 = lazy(() => import('../components/auth0.component/auth0'));
const EmailVerification = lazy(() => import('../components/auth0.component/emailVerification'));
const PhoneVerification = lazy(() => import('../components/auth0.component/phoneVerification'));
const SumSub = lazy(() => import('../components/sumSub.component/sumsub'));
const Notkyc = lazy(() => import('../components/sumSub.component/notKyc'));
const AccountStatus = lazy(() => import('../utils/accountStatus'));
const cases = lazy(() => import('../utils/cases'));

class RouteConfig extends Component {
  render() {
    return (
      <Suspense fallback={<h1 className="loader text-white app-loader" style={{ textAlign: "center", }}>Loading ....</h1>}>
        <Switch>
          <ReactRoute exact path={`/`}><Redirect to='/dashboard' /></ReactRoute>
          <ReactRoute path={`/welcome`}><Web3Dashboard /></ReactRoute>
          <ReactRoute path={`/dashboard`}><ProtectedRoute><DashboardComponent /></ProtectedRoute></ReactRoute>
          <ReactRoute path="/vaults"><ProtectedRoute><Vaults /></ProtectedRoute></ReactRoute>
          <ReactRoute exact path={`/payins`}><ProtectedRoute><PayIns /></ProtectedRoute></ReactRoute>
          <ReactRoute exact path={`/payins/payin/:id/:vaultName/:mode/:type?`}><ProtectedRoute><PayIn /></ProtectedRoute></ReactRoute>
          <ReactRoute exact path={`/payouts`}><ProtectedRoute><Payouts /></ProtectedRoute></ReactRoute>
          <ReactRoute exact path={`/payouts/payout/:id/:vaultName/:mode/:currencyType?`}><ProtectedRoute><Payout /></ProtectedRoute></ReactRoute>
          <ReactRoute exact path={`/payouts/transaction/:id?/:type?`}><ProtectedRoute><TransactionDetails screen='Pay-Outs' screenUrl={'/payouts'} /></ProtectedRoute></ReactRoute>
          <ReactRoute exact path={`/batchpayouts`}><ProtectedRoute><BatchPayouts /></ProtectedRoute></ReactRoute>
          <ReactRoute exact path={`/batchpayouts/payout/:id/:vault/:mode`}><ProtectedRoute><Payment /></ProtectedRoute></ReactRoute>
          <ReactRoute exact path={`/transactions`}><Transactions /></ReactRoute>
          <ReactRoute exact path={`/transactions/transaction/:id?/:type?`}><TransactionDetails screen='Transactions' screenUrl={'/transactions'} /></ReactRoute>
          <ReactRoute path={`/payees/:type/:id?/:name?/:mode?/:step?`}><ProtectedRoute><Payees /></ProtectedRoute></ReactRoute>
          <ReactRoute path="/callback" component={CallbackPage} />
          <ReactRoute path="/auth0" component={Auth0} />
          <ReactRoute path="/emailVerification" component={EmailVerification} />
          <ReactRoute path="/phoneVerification" component={PhoneVerification} />
          <ReactRoute path="/sumsub" component={SumSub} />
          <ReactRoute path="/notkyc" component={Notkyc} />
          <ReactRoute path="/accountstatus" component={AccountStatus} />
          <ReactRoute path="/cases" component={cases} />
        </Switch>
      </Suspense>
    );
  }
}

export default RouteConfig;
