
import { create } from "apisauce";
import { store } from "../store";


const walletsApi = create({
    baseURL: process.env.REACT_APP_API_END_POINT
});
const setupRequestInterceptor = (client) => {
    client.axiosInstance.interceptors.request.use((config) => {
      const access_token = store.getState().oidc?.deviceToken;
      config.headers.Authorization = `Bearer ${access_token}`;
      return config;
    });
  };
const walletsgridClient = create({
    baseURL : process.env.REACT_APP_API_END_POINT
})
const web3Client = create({
    baseURL:process.env.REACT_APP_WEB3_API_END_POINT
})
const apiClient = create({
    baseURL: process.env.REACT_APP_API_END_POINT
});
const ipStackClient = create({
    baseURL: process.env.REACT_APP_IPSTACK_API
})
const ipRegistry = create({
    baseURL: 'https://api4.ipregistry.co'
})
const uploadClient=create({
    baseURL: process.env.REACT_APP_UPLOAD_END_POINT
})
setupRequestInterceptor(walletsApi);
setupRequestInterceptor(walletsgridClient);
setupRequestInterceptor(apiClient);
export { ipRegistry,walletsApi,walletsgridClient,apiClient,ipStackClient,web3Client,uploadClient}
