import { useState } from "react";
import Web2Wrapper from "./web2Wrapper";
import Web3Wrapper from "./web3Wrapper";

const RootWrapper = ({ children }) => {
    const [isWeb2] = useState(process.env.REACT_APP_WALLET_TYPE === "custodial");
    if (isWeb2) {
        return <Web2Wrapper>{children}</Web2Wrapper>
    } else {
        return <Web3Wrapper>{children}</Web3Wrapper>
    }
}
export default RootWrapper;