import { getLooups } from "../components/batchPayouts/services";
import { deriveErrorMessage } from "../utils/deriveErrorMessage";


const SET_BATCH_PAYMENT_DETAILS = "setPaymentDetails"
const SETMERCHANTSDETAILS ='setMerchantsDetails';

const setBatchPaymentDetails = (payload) => {
    return {
        type: SET_BATCH_PAYMENT_DETAILS,
        payload
    }
}

export const setMerchantsDetails = (payload) => {
    return {
        type: SETMERCHANTSDETAILS,
        payload
    };
}

let initialState = {
    batchPaymentDetails: null,
    marchantsDetails : { loading: false, data: [] , error:null },
}


export const fetchMerchantsDetails = (customerId) => async (dispatch) => {
    try {
        dispatch(dispatch(setMerchantsDetails({ key: "marchantsDetails", loading: true, data: [], error: null })));
        const response = await getLooups('Merchant/GetMerchantDetails', customerId);
        if (response.success) {
            dispatch(dispatch(setMerchantsDetails({ key: "marchantsDetails", loading: false, data: response.data, error: null })));
        } else {
            dispatch(dispatch(setMerchantsDetails({ key: "marchantsDetails", loading: false, data: [], error: deriveErrorMessage(response) })));
        }
    } catch (error) {
        dispatch(dispatch(setMerchantsDetails({ key: "marchantsDetails", loading: false, data: [], error: deriveErrorMessage(error) })));
    }
}

const batchPayoutsReducer = (state , action) => {
    state = state || initialState
    switch (action.type) {
        case SETMERCHANTSDETAILS: 
            return {
                ...state, [action.payload.key]: {
                    data: action.payload.data,
                    error: action.payload.error,
                    loading: action.payload.loading
                }
            }
        case SET_BATCH_PAYMENT_DETAILS: 
            return { ...state, batchPaymentDetails: action.payload }
        default: 
            return state;
    }
}
export default batchPayoutsReducer;
export {setBatchPaymentDetails}