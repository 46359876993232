import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./auth0/config";
import history from "./auth0/history";
import { WagmiConfig } from "wagmi";
import { wagmiEntity } from "./web3Config";

const Web2Wrapper = ({ children }) => {
  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };

  const config = getConfig();

  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams: {
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      ...(config.audience ? { audience: config.audience } : null),
    },
    onRedirectCallback,
  };
  return <Auth0Provider {...providerConfig}>
    <WagmiConfig config={wagmiEntity}>
    {children}
    </WagmiConfig>
    </Auth0Provider>;
};

export default Web2Wrapper;
