import apicalls from "../api/apiCalls";
import { deriveErrorMessage } from "../utils/deriveErrorMessage";

const SET_VAULTS = "setVaults";
const SET_SELECTED_VAULT = "setSelectedVault"
const SET_SELECTED_COIN="setSelectedCoin"
const setSelectedCoin = (payload=null) => {
    return { type: SET_SELECTED_COIN, payload };
};

const setSelectedVault = (payload=null) => {
    return { type: SET_SELECTED_VAULT, payload };
};

const setVaults = (payload = { loader: false, data: null, error: "" }) => {
    return { type: SET_VAULTS, payload };
}
const fetchVaults = (userId) => {
    return async (dispatch) => {
        dispatch(
            setVaults({
                loader: true,
                data: null,
                error: "",
            })
        );
        try {
            const response = await apicalls.getMerchantLU(userId);
            if (response.ok) {
                dispatch(
                    setVaults({
                        loader: false,
                        data: response?.data,
                        error: "",
                    })
                );
            } else {
                dispatch(
                    setVaults({
                        loader: false,
                        data: null,
                        error: deriveErrorMessage(response),
                    })
                );
            }
        } catch (error) {
            dispatch(
                setVaults({
                    loader: false,
                    data: null,
                    error: error.message,
                })
            );
        }
    };
}


const initialState = {
    vaults: { loader: true, data: null, error: "" },
    selectedVault: null,
    selectedCoin:null,
};
const vaultsAccordionReducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case SET_VAULTS:
            return {
                ...state,
                vaults: {
                    ...action.payload,
                },
            }
        case SET_SELECTED_VAULT: return { ...state, selectedVault: action.payload }
        case SET_SELECTED_COIN:return { ...state, selectedCoin: action.payload }
        default:
            return state;
    }
};
export default vaultsAccordionReducer;
export { fetchVaults, setSelectedVault, setVaults,setSelectedCoin };
