import React from "react";
import { useSelector } from "react-redux";
import { useHistory ,useLocation} from "react-router-dom";
import Loader from "../shared/loader";
import {useAccount,useConnect} from 'wagmi';
import Cases from "../utils/cases";
const walletType=process.env.REACT_APP_WALLET_TYPE
const ProtectedRoute = ({ children }) => {
    const {isLoading}=useConnect()
    const {isConnecting,isDisconnected,isReconnecting}=useAccount()
    const {pathname}=useLocation()
  const history = useHistory();
  const { userProfileInfo, gettingUserInfo } = useSelector(
    (store) => store.userConfig
  );
  const casesInfo = useSelector((store) => store.userConfig.userCasesInfo?.data);
  
  if (
    (walletType === "non_custodial" &&
    ((!userProfileInfo|| !userProfileInfo?.id || isDisconnected) && !gettingUserInfo && !isConnecting && !isReconnecting && !isLoading))
  ) {
    history.replace("/");
    history.push("/welcome");
    return <></>;
  }
  if ((walletType === "non_custodial" && (gettingUserInfo || isConnecting || isReconnecting)) || !userProfileInfo|| !userProfileInfo?.id) {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }
  if(walletType !== "non_custodial" && ( pathname !=='/dashboard'  && casesInfo?.length>0)){
    return <Cases/>
  }

  return <>{children}</>;
};

export default ProtectedRoute;
