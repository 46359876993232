import {publishTransactionRefresh} from '../utils/pubsub'
const SET_ACCOUNTDETALS = "setAccountDetails"
const SET_NOTIF_COUNT = "setNotificationCount";
const USER_APPS="userAppsData"
const setAccountDetails = (payload) => {
    return {
        type: SET_ACCOUNTDETALS,
        payload
    }
}

const setNotificationCount = (payload) => {
    return {
        type: SET_NOTIF_COUNT,
        payload
    }
}
const fetchDashboardcalls = () => {
    return async () => {
        publishTransactionRefresh();
    }
}
const userAppsData=(payload)=>{
    return {
        type:USER_APPS,
        payload
    }
}
let initialState = {
    accountDetails: [],
    notificationCount: 0,
    getUserApps:{loading:false,data:[]},

}


const dashboardReducer = (state, action) => {
    if(!state){
        state={...initialState,...state}
      }
    switch (action.type) {

        case SET_ACCOUNTDETALS:
            return { ...state, accountDetails: action.payload };
        case SET_NOTIF_COUNT:
            let _count = action.payload
            if (action?.payload < 0) {
                _count = 0;
            }
            state = { ...state, notificationCount: _count }
            return state;
        case USER_APPS:
            return {...state,getUserApps:{data:action.payload.data,  loading:action.payload.loading}}
        default:

            return state;
    }
}
export default dashboardReducer;
export { setAccountDetails, setNotificationCount,fetchDashboardcalls,userAppsData }
