import Layout from "./layout";
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from "./store";
import { OidcProvider } from 'redux-oidc';
import { useEffect, useState } from "react";
import { userManager } from "./auth";
import { useAuth0 } from "@auth0/auth0-react";
import {updatetwofactor} from './reducers/configReduser'
import apicalls from './api/apiCalls'
import {startConnection} from './utils/signalR'
import Notifications from '../src/components/notifications.component'
function App() {
  const { isLoading,isAuthenticated,loginWithRedirect } = useAuth0();
  const [, setLoading] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const connectToHub = () => {
    setTimeout(() => {
      const { userConfig: { userProfileInfo } } = store.getState();
      if (userProfileInfo?.id) {
        apicalls.twofactor(userProfileInfo?.id).then(res => {
          if (res.ok) {
            store.dispatch(updatetwofactor({ loading: false, isEnabled: res.data }));
          }
        })
        startConnection(userProfileInfo?.id);
      } else {
        connectToHub();
      }
    }, 2000)

  }


  useEffect(() => {

    localStorage.setItem("__url", window.location.pathname.includes('callback')?'/dashboard':window.location.pathname);
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
    else {
      setLoading(false)
    }

    connectToHub();


  }, []);


  return (
    <OidcProvider userManager={userManager} store={store}>
      <Router basename={process.env.PUBLIC_URL}>
            {(isLoading && process.env.REACT_APP_WALLET_TYPE!=='non_custodial') ? <h1 className="loader text-white app-loader" style={{textAlign:"center",}}>Loading ....</h1> :
            <><Layout /></>}
                      <Notifications showDrawer={showNotifications} onClose={() => setShowNotifications(false)} />
      </Router>
    </OidcProvider>
  );
}

export default App;