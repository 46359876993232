
const ERROR_MESSAGES = {
    DEFAULT: "Something went wrong, please try again!",
    UNHANDLED_EXCEPTION: "An unhandled exception occurred.",
    SERVICE_ISSUE: "Service issue, Please contact Administrator!",
    PERMISSION_DENIED: "You don't have permission, Please contact Administrator!",
    SERVER_DOWN: "Server down! Please contact Administrator!",
    VALIDATION_ERROR: "Please enter valid data"
};

export const deriveErrorMessage = (errorToDerive) => {
    const getTitle = (data) =>
        data?.title && typeof data.title === "string" && data.title.length < 150
            ? data.title
            : null;

    const getErrorsMessage = (errors) =>{
        if(errors && typeof errors==='object'){
            return Object.values(errors)?.map((fieldErrors) =>typeof fieldErrors[0] === "string" ? fieldErrors[0] : ERROR_MESSAGES.VALIDATION_ERROR)?.join(",")
        }
        return ERROR_MESSAGES.UNHANDLED_EXCEPTION
    }
    const { status, data, response } = errorToDerive;

    switch (status) {
        case 522:
            return getTitle(data) || ERROR_MESSAGES.UNHANDLED_EXCEPTION;
        case 400:
            return data?.errors
                ? getErrorsMessage(data.errors)
                : getTitle(data) || ERROR_MESSAGES.UNHANDLED_EXCEPTION;
        case 500:
            return getTitle(data) || ERROR_MESSAGES.SERVICE_ISSUE;
        case 480:
            return data?.errors?.['DomainValidations']?.[0] ? data?.errors?.['DomainValidations']?.[0] : getTitle(data) || ERROR_MESSAGES.SERVICE_ISSUE;
        case 403:
            return getTitle(data) || ERROR_MESSAGES.PERMISSION_DENIED;
        default:
            if (response?.status === 502 || response?.status === 503) {
                return ERROR_MESSAGES.SERVER_DOWN;
            }
            return ERROR_MESSAGES.DEFAULT;
    }
}