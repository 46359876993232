
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
const defaultClass='breadcrumb-margin'
const ComponentsBreadcrumb = (props) => {
  const extraBreadcrumbItems = props.breadCrumbList?.map((name) => {
    return (
      <Breadcrumb.Item key={name.id+name.bname}>
        {name?.url && <Link to={name.url} className={'breadcrumb-link c-pointer'}>{name.bname}</Link>}
        {!name.url && <span className='breadcrumb-item'>{name.bname}</span>}
      </Breadcrumb.Item>

    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="/dashboard"></Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (<Breadcrumb className={`app-breadcrumb ${props.className || defaultClass}`} separator={<span className='icon sm bc-arrow' />}>{breadcrumbItems}</Breadcrumb>)
}

export default ComponentsBreadcrumb;