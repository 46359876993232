export function checkCustomerState(config) {
    if (!config) {
        return false
    }
    const hasProp = config.hasOwnProperty("customerState");
    return ((hasProp && config.customerState === "Approved") || !hasProp) ? true : false;
}
export const convertUTCToLocalTime = (dateString) => {
    let date = new Date(dateString);
    const milliseconds = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );
    return new Date(milliseconds)
};
