import { useState } from 'react'
import formatError from './formatError';
import { arcanaAuth } from '../auth/web3Config';

const useSwitchNetwork = () => {
    const [switching,setSwitching]=useState(false);
    const switchNetwork = async (chaindId) => {
        setSwitching(true)
        try{
            await arcanaAuth?.provider?.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId:chaindId }],
            });

        }catch(err){
            return formatError(err,'contract')
        }finally{
            setSwitching(false)
        }
    }
  return {switching,switchNetwork}
}

export default useSwitchNetwork