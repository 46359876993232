import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAccount, useConnect } from "wagmi";
const ConnectWeb3 = ({ buttonClassName, onSuccess, onError, triggerLogin,setTriggerLogin,hasIcon=false,IconComponent }) => {
  const { connectAsync, connectors, isLoading } = useConnect();
  const { isConnecting, isReconnecting } = useAccount();
  const { gettingUserInfo } = useSelector((store) => store.userConfig);
  useEffect(() => {
    if (triggerLogin) {
      handleWeb3Login();
      setTriggerLogin?.(false)
    }
  }, [triggerLogin]);
  const handleWeb3Login = async () => {
    try {
      const response = await connectAsync({ connector: connectors[0] });
      await onSuccess?.(response);
    } catch (err) {
      await onError?.(err);
    }
  };
  return (
    <button className={buttonClassName} onClick={() => handleWeb3Login()}>
      {(isLoading ||isConnecting || isReconnecting || gettingUserInfo) 
        ? "Connecting ..."
        : <>
        <span>Login</span>
        {hasIcon && IconComponent}
        </>}
        
    </button>
  );
};

export default ConnectWeb3;
