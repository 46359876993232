const SET_BREADCRUMB = 'setBreadcrumb';
const CLEAR_BREADCRUMB = 'clearBreadcrumb';
const setBreadcrumb = (payload) => {

    return {
        type: SET_BREADCRUMB,
        payload
    }
}
const clearBreadcrumb = (payload) => {
    return {
        type: CLEAR_BREADCRUMB,
        payload
    }
}

const initialState = {
    values: {
        "/exchanges":"Exchanges",
        "/Fireblocks":"Fireblocks",
        "/dashboard/custodian":"Custodians",
        "/transfer":"Transfer",
    }
}

const breadCrumbReducer = (state , action) => {
    if(!state){
        state={...initialState,...state}
      }
    switch (action.type) {
        case SET_BREADCRUMB:
            state = { values: { ...state.values, [action.payload.key]: action.payload.val } };
            return state;
        case CLEAR_BREADCRUMB:
            state = { values: initialState.values };
            return state;
        default:
            return state;
    }
}
export { setBreadcrumb, clearBreadcrumb };
export default breadCrumbReducer;