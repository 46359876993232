import React from "react";
import { useSelector } from "react-redux";
import ComponentsBreadcrumb from "../layout/componentsBreadcrumb";
import { Carousel } from "antd";
import { useLocation } from "react-router-dom";
import Loader from "../shared/loader";
const profileUrl = process.env.REACT_APP_PROFILE_URL


const Cases =()=>{
  const {pathname}=useLocation()
  const pageName = pathname?.split('/')[1]
   const casesInfo = useSelector((store) => store.userConfig.userCasesInfo?.data);
  const breadCrumbList = [
    { id: '1', bname: 'Artha Pay', url: '/dashboard' },
    { id: '2', bname: pageName?.charAt(0).toUpperCase() + pageName?.slice(1) }
  ];
    const handleNavigateToProfile = (id)=>{
      window.open(`${profileUrl}/userprofile/cases/${id}`, "_blank" );
    };
    
   return(<>
   <ComponentsBreadcrumb breadCrumbList={breadCrumbList} className={'m-0 mb-8'}/>
   {casesInfo?.loader && <Loader />}
   {!casesInfo?.loader && casesInfo?.length>0 &&
        <Carousel autoplay className="cases-slider" >
          {casesInfo?.map((item) => (
            <div key={item?.id} className='cases-carousal d-flex justify-content align-center'>
             <div className="d-flex align-center">
              <div><span className="icon icon-close mr-16"></span></div>
             <div>
             <h2 className="carousal-title mb-0">{item.title}</h2>
             <p className="carousal-content">{item.message}</p>
             </div>
             </div>
              <p onClick={()=>handleNavigateToProfile(item?.typeId)} className="see-details">View details</p>
            </div>
          ))}
        </Carousel>
      }
   </>)
}
export default Cases