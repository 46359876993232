import { Component } from 'react'
import { Layout } from 'antd';
import RouteConfig from './routeConfig';
import ConnectStateProps from '../utils/connectStateProps';
import { withRouter } from "react-router-dom";
import { checkCustomerState } from '../utils/service'

const { Content } = Layout;
class AppContent extends Component {
  state = {
    isWeb3:process.env.REACT_APP_WALLET_TYPE==='non_custodial'
  }
  componentDidMount() {
    if(!this.state.isWeb3){
     this.handleWeb2Redirections()
    }
  }
  handleWeb2Redirections(){
     if (!this.props.userProfile?.isCustomerUpdated) {
      this.props.history.push('/auth0');
    }
    else if (!this.props?.userProfile?.isKYC) {
      this.props.history.push('/sumsub');
    }
    else if (!this.props.userProfile?.isEmailVerified) {
      this.props.history.push('/emailVerification');
    }
    else if (!this.props.userProfile?.isPhoneNumberVerified) {
      this.props.history.push('/phoneVerification');
    }
    else if (this.props.userProfile?.isKYC && !checkCustomerState(this.props.userProfile)) {
      this.props.history.push("/accountstatus")
    } else {
      this.props.history.push("/dashboard")
    }
  }
  render() {
    return <>
      <div className="main-container">
        <Content>
          <RouteConfig />
        </Content>
      </div>
    </>
  }
}

export default ConnectStateProps(withRouter(AppContent));