import { getPayeesGrid } from "../components/payees/api";

const GET_FIAT_PAYEES_LIST = "fetchFiatPayees";
const GET_CRYPTO_PAYEES_LIST = "fetchCryptoPayees";
const CRYPTO_PAYEE_DETAILS = "setCryptoPayeeDetails";
const SET_SATOSHI_DETAILS = "setSatoshiTestDetails";

const handlePayeeCryptoDataFetch = (payload) => {
  return {
    type: GET_CRYPTO_PAYEES_LIST,
    payload,
  };
};

const handlePayeeFiatDataFetch = (payload) => {
  return {
    type: GET_FIAT_PAYEES_LIST,
    payload,
  };
};
const setCryptoPayeeDetails = (payload) => {
  return {
    type: CRYPTO_PAYEE_DETAILS,
    payload,
  };
};

const setSatoshiTestDetails = (payload) => {
  return {
    type: SET_SATOSHI_DETAILS,
    payload,
  };
};

const fetchFiatPayees = (id, pageNo, pageSize, data, search = "all") => {
  return async (dispatch) => {
    dispatch(
      handlePayeeFiatDataFetch({
        key: "fiatPayees",
        loader: true,
        data: data || null,
        error: "",
        nextPage: pageNo,
      })
    );
    try {
      const response = await getPayeesGrid(id, "fiat", pageNo, pageSize, search);
      if (response.ok) {
        dispatch(
          handlePayeeFiatDataFetch({
            key: "fiatPayees",
            loader: false,
            data: data ? [...data, ...response.data] : response.data,
            error: "",
            nextPage: pageNo + 1,
          })
        );
      } else {
        dispatch(
          handlePayeeFiatDataFetch({
            key: "fiatPayees",
            loader: false,
            data: data,
            pageNo: pageNo,
            error: response.originalError.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        handlePayeeFiatDataFetch({
          key: "fiatPayees",
          loader: false,
          error: error.message || error,
          data: data,
          pageNo: pageNo,
        })
      );
    }
  };
};
const fetchCryptoPayees = (id, pageNo, pageSize, data, search = "all") => {
  return async (dispatch) => {
    dispatch(
      handlePayeeCryptoDataFetch({
        key: "cryptoPayees",
        loader: true,
        data: data || null,
        error: "",
        nextPage: pageNo,
      })
    );
    try {
      const response = await getPayeesGrid(id, "crypto", pageNo, pageSize, search);
      if (response.ok) {
        dispatch(
          handlePayeeCryptoDataFetch({
            key: "cryptoPayees",
            loader: false,
            data: data ? [...data, ...response.data] : response.data,
            error: "",
            nextPage: pageNo + 1,
          })
        );
      } else {
        dispatch(
          handlePayeeCryptoDataFetch({
            key: "cryptoPayees",
            loader: false,
            data: data,
            pageNo: pageNo,
            error: response.originalError.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        handlePayeeCryptoDataFetch({
          key: "cryptoPayees",
          loader: false,
          error: error.message || error,
          data: data,
          pageNo: pageNo,
        })
      );
    }
  };
};
const initialState = {
  fiatPayees: { loader: false, data: null, error: "", nextPage: 1 },
  cryptoPayees: { loader: false, data: null, error: "", nextPage: 1 },
  cryptoPayeeDetails: null,
  satoshiTestDetails: null
};
const PayeeReducer = (state, action) => {
  state = state || initialState
  switch (action.type) {
    case GET_CRYPTO_PAYEES_LIST:
      state = {
        ...state,
        cryptoPayees: { ...state["cryptoPayees"], ...action.payload },
      };
      return state;
    case GET_FIAT_PAYEES_LIST:
      state = {
        ...state,
        fiatPayees: { ...state["fiatPayees"], ...action.payload },
      };
      return state;
    case CRYPTO_PAYEE_DETAILS:
      state = {
        ...state, cryptoPayeeDetails: action.payload
      };
      return state
    case SET_SATOSHI_DETAILS:
      state = {
        ...state, satoshiTestDetails: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default PayeeReducer;
export { fetchFiatPayees, fetchCryptoPayees, setCryptoPayeeDetails, setSatoshiTestDetails };
